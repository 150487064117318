<template>
	<div v-if="isReady" class="wrap" :class="{ animate: animate }" :data-theme="theme">
		<router-view v-slot="{ Component }">
			<transition :name="route_transition">
				<component :is="Component" />
			</transition>
		</router-view>
	</div>

	<div id="log"></div>
	<Loader :show="isLoading || !isReady" />
	<Notification />
</template>

<script>
import { computed, onMounted, ref, inject } from '@vue/runtime-core'
import { useStore } from 'vuex'
import Loader from '@/components/Loader'
import useApi from '@/composition/api'
import Notification from '@/components/Notification.vue'
import { useTheme } from '@/composition/theme'
import { useQueryParam } from '@/composition/location'
import { setI18nLanguage } from './i18n'
import { useRouter } from 'vue-router'

// import { makeFakeData, makeFakeUserData, EXPERIMENTS_CONFIG_MAP, FAKE_USER_DATA } from '@/data/fakeData'

export default {
	components: {
		Loader,
		Notification
	},

	setup() {
		const store = useStore()
		const router = useRouter()
		const api = useApi()
		const { theme } = useTheme()
		const amplitude = inject('amplitude')

		const isAndroid = api.getPlatform() == 'android'

		store.commit('setSource', useQueryParam('source'))
		window.fs_setSource = (source) => {
			store.commit('setSource', source)
		}

		window.fs_setPurchasedProductsAndEntitlements = (source) => {
			console.log(source)
		}

		/**
		 * @param {('development'|'production')} env App env variable
		 */
		window.fs_environment = (env) => {
			store.commit('env/setEnv', env)
		}

		/**
		 * TODO
		 * Environment support in android paywall. Will be fixed in the next release
		 */
		if (isAndroid) {
			const isProductionHost = window.location.host.includes('paywall.simple-life-app.com')
			window.fs_environment(isProductionHost ? 'production' : 'development')
		}

		window.fs_setTopAndBottomInsets = (top, bottom) => {
			store.commit('setOffsets', { top, bottom })
		}
		window.fs_setUserId = (user_id) => {
			store.dispatch('user/initializeBrazeUser', user_id)
		}
		window.fs_setUserData = (data) => {
			store.commit('user/setUserData', data)
		}
		window.fs_setUserEmail = (email) => {
			store.commit('user/setEmail', email)
		}

		window.fs_updateProducts = (products) => {
			store.commit('products/store', products)
		}
		window.fs_startWithConfigAndProducts = (config, products) => {
			store.commit('config/store', config)
			store.commit('products/store', products)
		}

		window.fs_setIsLoading = (isLoading) => {
			store.commit('setLoading', isLoading)
		}
		window.fs_setIsPurchasing = (isPurchasing) => {
			store.commit('setLoading', isPurchasing)
		}

		// makeFakeData(0, EXPERIMENTS_CONFIG_MAP.DEV_15325)
		// makeFakeUserData(0, FAKE_USER_DATA)

		// setTimeout(() => {
		// 	window.fs_setUserId('1c2a4e7c-62fc-49e2-b1ba-b37639f488ac')
		// 	window.fs_setUserEmail('paukovdenis+wp8@gmail.com')
		// 	window.fs_updateProducts(require('../src/data/products.json'))
		// }, 1)

		let animate = ref(false)
		let route_transition = ref(null)
		const isReady = ref(false)

		/** Loading */
		let isLoading = computed(() => store.state.isLoading)

		onMounted(() => {
			const isMobile = window.matchMedia('(max-width: 991px)').matches
			try {
				amplitude.logEvent('PaywallInit', {
					config: store.getters['config/getConfigJsonString'],
					fullPath: router.currentRoute.value.path,
					data_source: 'client',
					platform: api.getPlatform(),
					isMobile: isMobile,
					source: store.getters['getSource']
				})
			} catch (error) {
				console.error('Log error: ', error)
			}

			setTimeout(() => {
				const platformAnimation = isAndroid ? 'fade' : 'next'
				route_transition.value = isMobile ? platformAnimation : 'fade'
			}, 500)
		})

		api.init().finally(() => {
			const language = useQueryParam('lang', api.getLanguage())
			setI18nLanguage(language).then(() => {
				isReady.value = true
			})
		})

		return {
			animate,
			route_transition,
			isLoading,
			isReady,
			theme
		}
	}
}
</script>

<style lang="scss">
@import '@/scss/styles.scss';
</style>
