import { createI18n } from 'vue-i18n'
import useApi from '@/composition/api'
import useEnv from '@/composition/env.js'

const api = useApi()
const env = useEnv()
const languages = ['en', 'es', 'es-419', 'pt', 'de', 'it', 'fr', 'tr', 'sv', 'nb', 'da', 'nl', 'pl']

const loadLocaleMessages = async (locale) => {
	try {
		return await fetch(`${env('LOCALIZATION_URL')}/${locale}/web-paywall.json`, {
			method: 'GET',
			mode: 'cors'
		}).then((response) => response.json())
	} catch (error) {}
}

const i18n = createI18n({
	legacy: false,
	locale: api.getLanguage(),
	fallbackLocale: 'en'
})

const setI18nLanguage = async (lang) => {
	const currentLanguage = languages.includes(lang) ? lang : 'en'

	return loadLocaleMessages(currentLanguage).then((messages) => {
		i18n.global.setLocaleMessage(currentLanguage, messages)
		i18n.global.locale.value = currentLanguage
		document.querySelector('html').setAttribute('lang', currentLanguage)
	})
}
export { i18n, setI18nLanguage }
